$blue: #5A91CB;
$blue-light: rgba(102, 175, 233, 1);
$blue-lighter: rgba(102, 175, 233, 0.6);
$green-light: #41DB00;
$green-lighter: rgba(146, 237, 107, 0.6);
$red-light: #ff5b5b;
$red-lighter: rgba(255, 90, 90, 0.6);
$yellow-light: #ffdb00;
$yellow-lighter: rgba(255, 231, 84, 0.6);
$dark-light: #333;
$dark-lighter: rgba(55, 55, 55, 0.6);
$dark-glass: rgba(0, 0, 0, 0.8);
$dark: #333;
$yellow: rgba(255, 212, 100, 1);
$purple: #c7c0de;

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

.easy-autocomplete {
	position: relative;
    width: 100% !important;

	input {
		border: {
			color: #ccc;
			radius: 4px;
			style: solid;
			width: 1px;
		}
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
		color: #555;
		float: none;
		padding: 6px 12px;

		&:hover, &:focus {
			box-shadow: none;
		}
	}

	a {
		display: block;
	}


	&.eac-blue-light {

		input {

			&:hover, &:focus {
				border-color: $blue-light;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $blue-lighter;
			}
		}

		ul {
			border-color: $blue-light;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $blue-lighter;

			li, .eac-category {
				border-color: $blue-light;

				&.selected {
					background-color: lighten($blue-light, 30%);
				}

			}
		}
	}

	&.eac-green-light {

		input {
			&:hover, &:focus {
				border-color: $green-light;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $green-lighter;
			}
		}

		ul {
			border-color: $green-light;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $green-lighter;

			li, .eac-category {
				border-color: $green-light;

				&.selected {
					background-color: lighten($green-light, 30%);
				}

			}
		}
	}

	&.eac-red-light {

		input {
			&:hover, &:focus {
				border-color: $red-light;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $red-lighter;
			}
		}

		ul {
			border-color: $red-light;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $red-lighter;

			li, .eac-category {
				border-color: $red-light;

				&.selected {
					background-color: lighten($red-light, 10%);
				}

			}
		}
	}

	&.eac-yellow-light {

		input {
			&:hover, &:focus {
				border-color: $yellow-light;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $yellow-lighter;
			}
		}

		ul {
			border-color: $yellow-light;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $yellow-lighter;

			li, .eac-category {
				border-color: $yellow-light;

				&.selected {
					background-color: lighten($yellow-light, 10%);
				}

			}
		}
	}

	&.eac-dark-light {

		input {
			&:hover, &:focus {
				border-color: $dark-light;
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $dark-lighter;
			}
		}

		ul {
			border-color: $dark-light;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $dark-lighter;

			li, .eac-category {
				border-color: $dark-light;

				&.selected {
					background-color: lighten($dark-light, 10%);
					color: #fff;
				}

			}
		}
	}

	&.eac-dark {
		color: #fff;

		input {
			background-color: lighten($dark-light, 5%);
			border: {
				radius: 4px;
			}
			box-shadow: 0;
			color: #f6f6f6;

			&:hover, &:focus {
				border-color: $dark;
				box-shadow: 0;
			}
		}

		ul {
			border-color: $dark;

			li, .eac-category {
				background-color: lighten($dark-light, 5%);
				border-color: $dark;

				&.selected {
					background-color: lighten($dark, 25%);
					color: #f6f6f6;
				}

			}
		}
	}

	&.eac-dark-glass {
		color: #fff;

		input {
			background-color: $dark-glass;
			border: {
				radius: 4px;
			}
			box-shadow: 0;
			color: #f6f6f6;

			&:hover, &:focus {
				border-color: $dark-glass;
				box-shadow: 0;
			}
		}

		ul {
			border-color: $dark-glass;

			li, .eac-category {
				background-color: $dark-glass;
				border-color: $dark-glass;

				&.selected {
					background-color: lighten($dark-glass, 25%);
					color: #f6f6f6;
				}

				&:last-child {
					border-radius: 0 0 4px 4px;
				}

			}
		}
	}


	&.eac-blue {
		color: #fff;

		input {
			@include placeholder {
				color: #f6f6f6;
			}
			background-color: lighten($blue, 5%);
			border: {
				radius: 4px;
			}
			box-shadow: 0;
			color: #f6f6f6;

			&:hover, &:focus {
				border-color: $blue;
				box-shadow: 0;
			}
		}

		ul {
			border-color: $blue;

			li, .eac-category {
				background-color: lighten($blue, 5%);
				border-color: $blue;

				&.selected {
					background-color: lighten($blue, 15%);
					color: #f6f6f6;
				}

			}
		}
	}

	&.eac-yellow {
		color: #333;

		input {
			background-color: lighten($yellow, 5%);
			border: {
				color: #333;
				radius: 4px;
			}
			box-shadow: 0;
			color: #333;

			&:hover, &:focus {
				border-color: #333;
				box-shadow: 0;
			}
		}

		ul {
			border-color: #333;

			li, .eac-category {
				background-color: lighten($yellow, 5%);
				border-color: #333;

				&.selected {
					background-color: lighten($yellow, 15%);
					color: #333;
				}

			}
		}
	}

	&.eac-purple {
		color: #333;

		input {
			background-color: lighten($purple, 5%);
			border: {
				color: darken($purple, 5%);
			}
			box-shadow: 0;
			color: #333;

			&:hover, &:focus {
				border-color: #333;
				box-shadow: 0;
			}
		}

		ul {
			border-color: #333;

			li, .eac-category {
				background-color: lighten($purple, 5%);
				border-color: #333;

				&.selected {
					background-color: lighten($purple, 12%);
					color: #333;
				}

			}
		}
	}

	&.eac-bootstrap {

		input {
			border-color: #ccc;
			border-radius: 4px;
			border-style: solid;
			border-width: 1px;
			color: #555;
			padding: 6px 12px;
		}
	}


}


.easy-autocomplete-container {
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 2;

	ul {
		background: none repeat scroll 0 0 #ffffff;
		border-top: 1px dotted #ccc;
		display: none;
		margin-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		position: relative;
		top: -1px;

		li, .eac-category {
			background: inherit;
			border: {
				color: #ccc;
				image: none;
				style: solid;
				width: 0 1px;
			}
			display: block;
			font: {
				size: 14px;
				weight: normal;
			}
			padding: 4px 12px;
		}

		li {

			&:last-child {
				border: {
					radius: 0 0 2px 2px;
					width: 0 1px 1px;
				}
			}

			&.selected {
				background: none repeat scroll 0 0 #ebebeb;
				cursor: pointer;

				div {
					font-weight: normal;
				}
			}

			div {
				display: block;
				font-weight: normal;
				word-break: break-all;
			}

			b {
				font: {
					weight: bold;
				}
			}
		}

		.eac-category {
			font: {
				color: #aaa;
				style: italic;
			}
		}

	}
}


.eac-description {

	.eac-item {

		span {
			color: #aaa;
			font-style: italic;
			font-size: 0.9em;
		}
	}
}

.eac-icon-left {

	.eac-item {
		img {
			margin-right: 4px;
			max-height: 30px;
		}
	}
}


.eac-icon-right {

	.eac-item {
		margin-top: 8px;
		min-height: 24px;
		position: relative;

		img {
			margin-left: 4px;
			max-height: 30px;
			position: absolute;
			right: -4px;
			top: -8px;
		}
	}
}
